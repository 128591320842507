import { render, staticRenderFns } from "./IntegralIncrease.vue?vue&type=template&id=31e1756e&scoped=true"
import script from "./IntegralIncrease.vue?vue&type=script&lang=js"
export * from "./IntegralIncrease.vue?vue&type=script&lang=js"
import style0 from "./IntegralIncrease.vue?vue&type=style&index=0&id=31e1756e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e1756e",
  null
  
)

export default component.exports