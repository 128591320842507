<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="ct_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ct_handleSelect">
                <el-menu-item index="ct_tag1">发卡信息表</el-menu-item>
            </el-menu>
        </div>
        <div class="sf_main" v-if="ct_activeIndex === 'ct_tag1'">
            <div class="sf_des">
                <el-descriptions class="margin-top" :column="5" size="mini" :labelStyle="label_style1" :colon="false">
                    <el-descriptions-item label="手机号" :span="1">
                        <el-input clearable v-model="form1.phone" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员级别" :span="1">
                        <el-select v-model="form1.vipLevelId" placeholder="请选择" clearable :size="size">
                            <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="卡片性质" :span="1">
                        <el-select v-model="form1.cardType" placeholder="请选择" :size="size" clearable>
                            <el-option label="购买" value="0"></el-option>
                            <el-option label="赠送" value="1"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <el-input clearable v-model="form1.carNum" placeholder="车牌号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车架号" :span="1">
                        <el-input clearable v-model="form1.carFrameNumber" placeholder="车架号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <el-input clearable v-model="form1.sellerId" :size="size" placeholder="介绍人搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="专属顾问">
                        <el-select clearable placeholder="请选择" v-model="form1.userid" size="small">
                            <el-option v-for="(item) in memList" :key="item.user_id" :label="item.nickname"
                                :value="item.user_id"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="发卡店" :span="1">
                        <el-select v-model="form1.storeId" placeholder="请选择" :size="size" clearable> 
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="操作时间" :span="2">
                        <el-date-picker size="small" clearable v-model="storageTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <div class="operateBtn">
                            <el-button type="primary" :size="size" @click="_cardPager('check')">查询</el-button>
                            <el-button type="primary" :size="size" @click="exportData" v-if="isExport == 1">导出数据</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="sf_footer">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" style="width:21rem">
                        <el-table-column prop="id" label="编号">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="会员姓名"></el-table-column>
                        <el-table-column prop="gender" label="性别">
                            <template slot-scope="scope">
                                <span v-if="scope.row.gender == '1'">男</span>
                                <span v-if="scope.row.gender == '2'">女</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="phone" label="联系电话" width="120"></el-table-column>
                        <el-table-column prop="vipLevelName" label="会员级别"  width="120"></el-table-column>
                        <el-table-column prop="cardType" label="卡片性质">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cardType == '0'">购买</span>
                                <span v-if="scope.row.cardType == '1'">赠送</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="storeName" label="发卡店"></el-table-column>
                        <el-table-column prop="userId" label="专属顾问" width="120"></el-table-column>
                        <el-table-column prop="selfBalance" label="客户自费"></el-table-column>
                        <el-table-column prop="salesBalance" label="销售赠送"></el-table-column>
                        <el-table-column prop="afterBalance" label="售后赠送"></el-table-column>
                        <el-table-column prop="insuranceBalance" label="金融保险"></el-table-column>
                        <el-table-column prop="carNum" label="车牌号码">
                            <template slot-scope="scope">
                                <div v-for="(item, index) in scope.row.clientCarDOList" :key="index">{{ item.carNum }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号码" width="200">
                            <template slot-scope="scope">
                                <div v-for="(item, index) in scope.row.clientCarDOList" :key="index">{{ item.carFrameNumber
                                }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="totalPoints" label="总增加积分"></el-table-column>
                        <el-table-column prop="credit" label="可用积分"></el-table-column>
                        <el-table-column prop="sellerId" label="介绍人"></el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_cardPager" :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>

                <div class="show_data">
                    <el-divider content-position="left" class="poi_title">汇总信息</el-divider>
                    <div class="summaryInfo_Bootom">
                        <div>记录数：{{ summaryData.size || 0 }}条
                            客户自费：{{ summaryData.selfBalance || 0 }}元
                            销售赠送：{{ summaryData.salesBalance || 0 }}元
                            售后赠送：{{ summaryData.afterBalance || 0 }}元
                            金融保险：{{ summaryData.insuranceBalance || 0 }}元
                            总增加积分：{{ summaryData.totalPoints || 0 }}分
                            可用积分：{{ summaryData.credit || 0 }}分
                            <!-- 冻结积分：{{ summaryData.countSum || 0 }}分 -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import * as api from '@/api/financialManage/selfFundedDetails'
import * as memberApi from '@/api/memberManage/index'
import { getStoredModel } from '@/api/memberDeposit';

export default {
    data() {
        return {
            ct_defaultIndex: 'ct_tag1',
            ct_activeIndex: 'ct_tag1',
            form1: {
                phone: "", //手机号
                vipLevelId: "", //会员等级
                storeId: "", //发卡店
                cardType: "", //卡片性质
                carNum: "", //车牌号
                carFrameNumber: '',
                sellerId: "", //介绍人
                startTime: "", //开始时间
                endTime: "", //结束时间
                userid: ''
            },
            pageData: {
                total: 0,
                pageSize: 10, //分页大小
                page: 1,//分页页码，数字类型
            },
            dataList: [],
            storageTime: '',
            vipLevelList: [],
            StoreList: [],
            userList: [],
            summaryData: {},
            Czlist: [],
            content_style: {
                'width': '15%',
            },
            size: 'small',
            label_style1: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            isExport: false,
            memList: []
        }
    },
    // 使用
    mounted() {
        this.getCommn()
        this._cardPager()
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        resetForm() {
            this.form1 = {
                phone: "", //手机号
                vipLevelId: "", //会员等级
                storeId: "", //发卡店
                cardType: "", //卡片性质
                carNum: "", //车牌号
                sellerId: "", //介绍人
                startTime: "", //开始时间
                endTime: "", //结束时间
            }
        },
        ct_handleSelect(key, keyPath) {
            this.ct_activeIndex = key
            this.pageData.page = 1
            this.resetForm()
            this._cardPager()
        },
        getCommn() {
            memberApi.getStaffList().then(res => {
                if (res.state == '200') {
                    this.memList = res.data
                }
            })
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
            getStoredModel({}).then(res => {
                this.Czlist = res.data
            })
            api.getUserList().then(res => {
                if (res.state == '200') {
                    this.userList = res.data
                }
            })
        },
        _cardPager(flag) {
            if (flag == 'check') {
                // 查询 分页置为1
                this.pageData.page = 1
            }
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.cardPager({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
            api.totalfKCard({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.summaryData = res.data
                }
            })
        },
        exportData() {
            let exportUrl = api.exportFkCard
            let fileName = '发卡信息表'
            exportUrl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        }
    },
}
</script>
<style scoped>
.platformContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sf_main {
    flex: 1;
    background-color: rgb(247, 248, 250);
    padding-top: .25rem;
    display: flex;
    flex-direction: column;
}

.sf_des {
    background-color: white;
    border: 1px solid rgb(235, 235, 235);
    padding: .25rem;
    border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
    padding-right: .25rem;
}

.sf_footer {
    flex: 1;
    margin-top: .125rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .125rem;
}

.sf_footer .tableStyle {
    width: 21rem !important;
    flex: 1;
    overflow-x: auto;
}

.sf_footer .show_data {
    width: 100%;
    height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
    display: flex;
    justify-content: flex-end;
}
</style>