import { render, staticRenderFns } from "./CardInfoForm.vue?vue&type=template&id=3583fc42&scoped=true"
import script from "./CardInfoForm.vue?vue&type=script&lang=js"
export * from "./CardInfoForm.vue?vue&type=script&lang=js"
import style0 from "./CardInfoForm.vue?vue&type=style&index=0&id=3583fc42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3583fc42",
  null
  
)

export default component.exports