<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="mcbs_defaultIndex" class="el-menu-demo" mode="horizontal" @select="mcbs_handleSelect">
                <el-menu-item index="mcbs_tag1">客户自费维保</el-menu-item>
                <el-menu-item index="mcbs_tag2">销售赠送</el-menu-item>
                <el-menu-item index="mcbs_tag3">售后赠送</el-menu-item>
                <el-menu-item index="mcbs_tag4">金融保险赠送</el-menu-item>
            </el-menu>
        </div>
        <div class="sf_main">
            <div class="sf_des">
                <el-descriptions class="margin-top" :column="5" size="mini" :labelStyle="label_style1" :colon="false">
                    <el-descriptions-item label="手机号" :span="1">
                        <el-input v-model="form1.phone" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员级别" :span="1">
                        <el-select v-model="form1.vipLevelId" placeholder="请选择" clearable :size="size">
                            <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <el-input v-model="form1.carNum" placeholder="车牌号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="车架号码" :span="1">
                        <el-input v-model="form1.carFrameNumber" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="介绍人" :span="1">
                        <el-input v-model="form1.sellerId" :size="size" placeholder="介绍人搜索"></el-input>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="发卡店" :span="1">
                        <el-select v-model="form1.hairCard" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="卡片性质" :span="1">
                        <el-select v-model="form1.cardType" placeholder="请选择" :size="size" clearable>
                            <el-option label="购买" value="0"></el-option>
                            <el-option label="赠送" value="1"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="充值方式" :span="1">
                        <el-select class="dg" placeholder="请选择" v-model="form1.remeThod" size="small" clearable>
                            <el-option v-for="(item, index) in Czlist" :key="index" :label="item.storedValueName"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="操作部门" :span="1">
                        <el-select v-model="form1.operatingDept" placeholder="请选择" :size="size">
                            <el-option v-for="item in operateDepartmentList" clearable :key="item.operateDepartment"
                                :label="item.label" :value="item.operateDepartment">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="操作门店" :span="1">
                        <el-select v-model="form1.operationCard" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="操作员工" :span="1">
                        <el-select v-model="form1.operatingName" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in userList" :key="item.id" :label="item.login" :value="item.login">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="积分规则" :span="1">
                        <el-select v-model="form1.operatingName" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in userList" :key="item.id" :label="item.login" :value="item.login">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="项目名称" :span="1">
                        <el-select v-model="form1.operatingName" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in userList" :key="item.id" :label="item.login" :value="item.login">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="操作时间" :span="2">
                        <el-date-picker size="small" clearable v-model="storageTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <div class="operateBtn">
                            <el-button type="primary" :size="size" @click="check">查询</el-button>
                            <el-button type="primary" :size="size" @click="exportData" v-if="isExport == 1">导出数据</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="sf_footer" v-if="mcbs_activeIndex == 'mcbs_tag1'">
                <el-tabs v-model="tag1_activeName" type="border-card" @tab-click="tag1_handleClick">
                    <el-tab-pane label="总汇总" name="tag1_summary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="name" label="客户姓名"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别"></el-table-column> -->
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前客户自费维保余额"></el-table-column>
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_selfPagerAll"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="分类汇总" name="tag1_categarySummary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="会员姓名" width="150"></el-table-column>
                                <el-table-column prop="telephone" label="联系电话" width="200"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费" width="200"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡" width="200"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前客户自费维护余额" width="200"></el-table-column>
                                <!-- <el-table-column prop="carNum" label="车牌号码" width="200"></el-table-column>
                                <el-table-column prop="carFrameNumber" label="车架号码" width="200"></el-table-column>
                                <el-table-column prop="model" label="车型"></el-table-column>
                                <el-table-column prop="actived" label="卡片状态" width="150">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.actived == '0'">购买</span>
                                        <span v-if="scope.row.actived == '1'">赠送</span>
                                    </template>
                                </el-table-column> -->
                                <!-- <el-table-column prop="constructionOrder" label="入会日期"></el-table-column>
                                <el-table-column prop="constructionOrder" label="办卡时间"></el-table-column> -->
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_selfPagerFl"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="sf_footer" v-if="mcbs_activeIndex === 'mcbs_tag2'">
                <el-tabs v-model="tag2_activeName" type="border-card" @tab-click="tag2_handleClick">
                    <el-tab-pane label="总汇总" name="tag2_summary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前销售赠送维保余额"></el-table-column>
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_salePagerAll"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="分类汇总" name="tag2_categarySummary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="会员姓名" width="150"></el-table-column>
                                <el-table-column prop="telephone" label="联系电话" width="200"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费" width="200"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡" width="200"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前销售赠送维保余额" width="200"></el-table-column>
                                <!-- <el-table-column prop="carNum" label="车牌号码" width="200"></el-table-column>
                                <el-table-column prop="carFrameNumber" label="车架号码" width="200"></el-table-column>
                                <el-table-column prop="model" label="车型"></el-table-column>
                                <el-table-column prop="actived" label="卡片状态" width="150">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.actived == '0'">购买</span>
                                        <span v-if="scope.row.actived == '1'">赠送</span>
                                    </template>
                                </el-table-column> -->
                                <!-- <el-table-column prop="constructionOrder" label="入会日期"></el-table-column>
                                <el-table-column prop="constructionOrder" label="办卡时间"></el-table-column> -->
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_salePagerFl"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="sf_footer" v-if="mcbs_activeIndex === 'mcbs_tag3'">
                <el-tabs v-model="tag3_activeName" type="border-card" @tab-click="tag3_handleClick">
                    <el-tab-pane label="总汇总" name="tag3_summary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="name" label="会员姓名"></el-table-column>
                                <el-table-column prop="telephone" label="联系电话" width="200"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别"></el-table-column> -->
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前售后赠送维保余额"></el-table-column>
                                <!-- <el-table-column prop="carNum" label="车牌号码" width="200"></el-table-column>
                                <el-table-column prop="carFrameNumber" label="车架号码" width="200"></el-table-column>
                                <el-table-column prop="model" label="车型"></el-table-column>
                                <el-table-column prop="actived" label="卡片状态"></el-table-column> -->
                                <!-- <el-table-column prop="constructionOrder" label="入会日期"></el-table-column>
                                <el-table-column prop="constructionOrder" label="办卡时间"></el-table-column> -->
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_afterPagerAll"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="分类汇总" name="tag3_categarySummary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="会员姓名" width="150"></el-table-column>
                                <el-table-column prop="telephone" label="联系电话" width="200"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费" width="200"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡" width="200"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前售后赠送维保余额" width="200"></el-table-column>
                                <!-- <el-table-column prop="carNum" label="车牌号码" width="200"></el-table-column>
                                <el-table-column prop="carFrameNumber" label="车架号码" width="200"></el-table-column>
                                <el-table-column prop="model" label="车型"></el-table-column>
                                <el-table-column prop="actived" label="卡片状态" width="150">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.actived == '0'">购买</span>
                                        <span v-if="scope.row.actived == '1'">赠送</span>
                                    </template>
                                </el-table-column> -->
                                <!-- <el-table-column prop="constructionOrder" label="入会日期"></el-table-column>
                                <el-table-column prop="constructionOrder" label="办卡时间"></el-table-column> -->
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_afterPagerFl"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="sf_footer" v-if="mcbs_activeIndex === 'mcbs_tag4'">
                <el-tabs type="border-card" v-model="tag4_activeName" @tab-click="tag4_handleClick">
                    <el-tab-pane label="总汇总" name="tag4_summary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="name" label="客户姓名"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别"></el-table-column> -->
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前保险赠送维保余额"></el-table-column>
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_financePagerAll"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="分类汇总" name="tag4_categarySummary">
                        <div class="tableStyle">
                            <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                                :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true">
                                <el-table-column prop="id" label="编号" width="70">
                                    <template slot-scope="scope">
                                        <span>{{ scope.$index + 1 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="会员姓名" width="150"></el-table-column>
                                <el-table-column prop="telephone" label="联系电话" width="200"></el-table-column>
                                <el-table-column prop="vipLevelName" label="会员级别" width="150"></el-table-column>
                                <el-table-column prop="currentTime" label="截止当前时间"></el-table-column>
                                <el-table-column prop="rechargeMoney" label="截止当前充值"></el-table-column>
                                <el-table-column prop="consumptionMoney" label="截止当前消费" width="200"></el-table-column>
                                <el-table-column prop="cardRefundMoney" label="截止当前退卡" width="200"></el-table-column>
                                <el-table-column prop="balanceMoney" label="截止当前售后赠送维保余额" width="200"></el-table-column>
                                <!-- <el-table-column prop="carNum" label="车牌号码" width="200"></el-table-column>
                                <el-table-column prop="carFrameNumber" label="车架号码" width="200"></el-table-column>
                                <el-table-column prop="model" label="车型"></el-table-column>
                                <el-table-column prop="actived" label="卡片状态" width="150">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.actived == '0'">购买</span>
                                        <span v-if="scope.row.actived == '1'">赠送</span>
                                    </template>
                                </el-table-column> -->
                                <!-- <el-table-column prop="constructionOrder" label="入会日期"></el-table-column>
                                <el-table-column prop="constructionOrder" label="办卡时间"></el-table-column> -->
                            </el-table>
                            <div class="sf_page">
                                <el-pagination background layout="prev, pager, next" :total="pageData.total"
                                    :page-size="pageData.pageSize" @current-change="_afterPagerFl"
                                    :current-page.sync="pageData.page">
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>

import * as api from '@/api/financialManage/selfFundedDetails'
import * as memberApi from '@/api/memberManage/index'
import { getStoredModel } from '@/api/memberDeposit';

export default {
    data() {
        return {
            mcbs_defaultIndex: 'mcbs_tag1',
            mcbs_activeIndex: 'mcbs_tag1',
            form1: {
                phone: '',
                vipLevelId: '',
                cardType: '',
                remeThod: '',
                sellerId: '',
                hairCard: '',
                carNum: '',
                startTime: '',
                endTime: ''
            },
            pageData: {
                total: 0,
                pageSize: 10, //分页大小
                page: 1,//分页页码，数字类型
            },
            dataList: [],
            storageTime: '',
            vipLevelList: [],
            StoreList: [],
            userList: [],
            summaryData: {},
            Czlist: [],
            content_style: {
                'width': '15%',
            },
            size: 'small',
            tag1_activeName: 'tag1_summary',
            tag2_activeName: 'tag2_summary',
            tag3_activeName: 'tag3_summary',
            tag4_activeName: 'tag4_summary',
            label_style1: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            isExport: false
        }
    },
    // 使用
    mounted() {
        this.getCommn()
        this._selfPagerAll()
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        resetForm() {
            this.form1 = {
                phone: '',
                vipLevelId: '',
                cardType: '',
                remeThod: '',
                sellerId: '',
                hairCard: '',
                carNum: '',
                startTime: '',
                endTime: ''
            }
        },
        getCommn() {
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            // memberApi.CarTypeGetList({}).then(res => {
            //     let { data } = res
            //     this.carList = data
            // })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
            getStoredModel({}).then(res => {
                this.Czlist = res.data
            })
            api.getUserList().then(res => {
                if (res.state == '200') {
                    this.userList = res.data
                }
            })
        },
        mcbs_handleSelect(key, keyPath) {
            this.mcbs_activeIndex = key
            this.pageData.page = 1
            this.resetForm()
            switch (key) {
                case 'mcbs_tag1':
                    this.tag1_handleClick({ name: 'tag1_summary' })
                    break;
                case 'mcbs_tag2':
                    this.tag2_handleClick({ name: 'tag2_summary' })
                    break;
                case 'mcbs_tag3':
                    this.tag3_handleClick({ name: 'tag3_summary' })
                    break;
                case 'mcbs_tag4':
                    this.tag4_handleClick({ name: 'tag4_summary' })
                    break;
                default:
                    break;
            }
        },
        tag1_handleClick(tab) {
            this.tag1_activeName = tab.name
            switch (this.tag1_activeName) {
                case 'tag1_summary':
                    this._selfPagerAll()
                    break;
                case 'tag1_categarySummary':
                    this._selfPagerFl()
                    break;
                default:
                    break;
            }
        },
        tag2_handleClick(tab) {
            this.tag2_activeName = tab.name
            switch (this.tag2_activeName) {
                case 'tag2_summary':
                    this._salePagerAll()
                    break;
                case 'tag2_categarySummary':
                    this._salePagerFl()
                    break;
                default:
                    break;
            }
        },
        tag3_handleClick(tab) {
            this.tag3_activeName = tab.name
            switch (this.tag3_activeName) {
                case 'tag3_summary':
                    this._afterPagerAll()
                    break;
                case 'tag3_categarySummary':
                    this._afterPagerFl()
                    break;
                default:
                    break;
            }
        },
        tag4_handleClick(tab) {
            this.tag4_activeName = tab.name
            switch (this.tag4_activeName) {
                case 'tag4_summary':
                    this._financePagerAll()
                    break;
                case 'tag4_categarySummary':
                    this._financePagerFl()
                    break;
                default:
                    break;
            }
        },
        check() {
            this.pageData.page = 1
            if (this.mcbs_activeIndex == 'mcbs_tag1' && this.tag1_activeName == 'tag1_summary') {
                this._selfPagerAll()
            } else if (this.mcbs_activeIndex == 'mcbs_tag1' && this.tag1_activeName == 'tag1_categarySummary') {
                this._selfPagerFl()
            } else if (this.mcbs_activeIndex == 'mcbs_tag2' && this.tag2_activeName == 'tag2_summary') {
                this._salePagerAll()
            } else if (this.mcbs_activeIndex == 'mcbs_tag2' && this.tag2_activeName == 'tag2_categarySummary') {
                this._salePagerFl()
            } else if (this.mcbs_activeIndex == 'mcbs_tag3' && this.tag3_activeName == 'tag3_summary') {
                this._afterPagerAll()
            } else if (this.mcbs_activeIndex == 'mcbs_tag3' && this.tag3_activeName == 'tag3_categarySummary') {
                this._afterPagerFl()
            } else if (this.mcbs_activeIndex == 'mcbs_tag4' && this.tag4_activeName == 'tag4_summary') {
                this._financePagerAll()
            } else if (this.mcbs_activeIndex == 'mcbs_tag4' && this.tag4_activeName == 'tag4_categarySummary') {
                this._financePagerFl()
            }
        },
        _selfPagerAll() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.selfPagerAll({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = []
                    this.dataList.push(res.data)
                    this.pageData.total = res.data.total
                }
            })
        },
        _selfPagerFl() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.selfPagerFl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records.map(item => {
                        return {
                            ...item,
                            carEngineNum: item.clientCarDOList[0]?.carEngineNum || '',
                            carFrameNumber: item.clientCarDOList[0]?.carFrameNumber || '',
                            carNum: item.clientCarDOList[0]?.carNum || '',
                            model: item.clientCarDOList[0]?.model || ''
                        }
                    })
                    this.pageData.total = res.data.total
                }
            })
        },
        _salePagerAll() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.salePagerAll({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = []
                    this.dataList.push(res.data)
                    this.pageData.total = res.data.total
                }
            })
        },
        _salePagerFl() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.salePagerFl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records.map(item => {
                        return {
                            ...item,
                            carEngineNum: item.clientCarDOList[0]?.carEngineNum || '',
                            carFrameNumber: item.clientCarDOList[0]?.carFrameNumber || '',
                            carNum: item.clientCarDOList[0]?.carNum || '',
                            model: item.clientCarDOList[0]?.model || ''
                        }
                    })
                    this.pageData.total = res.data.total
                }
            })
        },
        _afterPagerAll() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.afterPagerAll({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = []
                    this.dataList.push(res.data)
                    this.pageData.total = res.data.total
                }
            })
        },
        _afterPagerFl() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.afterPagerFl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records.map(item => {
                        return {
                            ...item,
                            carEngineNum: item.clientCarDOList[0]?.carEngineNum || '',
                            carFrameNumber: item.clientCarDOList[0]?.carFrameNumber || '',
                            carNum: item.clientCarDOList[0]?.carNum || '',
                            model: item.clientCarDOList[0]?.model || ''
                        }
                    })
                    this.pageData.total = res.data.total
                }
            })
        },
        _financePagerAll() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.financePagerAll({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = []
                    this.dataList.push(res.data)
                    this.pageData.total = res.data.total
                }
            })
        },
        _financePagerFl() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.startTime = this.storageTime[0]
                this.form1.endTime = this.storageTime[1]
            } else {
                this.form1.startTime = ''
                this.form1.endTime = ''
            }
            api.financePagerFl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records.map(item => {
                        return {
                            ...item,
                            carEngineNum: item.clientCarDOList[0]?.carEngineNum || '',
                            carFrameNumber: item.clientCarDOList[0]?.carFrameNumber || '',
                            carNum: item.clientCarDOList[0]?.carNum || '',
                            model: item.clientCarDOList[0]?.model || ''
                        }
                    })
                    this.pageData.total = res.data.total
                }
            })
        },
        exportData() {
            let exportUrl = {}
            let fileName = ''
            //#region 
            if (this.mcbs_activeIndex == 'mcbs_tag1' && this.tag1_activeName == 'tag1_summary') {
                exportUrl = api.selfExportAll
                fileName = '客户自费维保 总汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag1' && this.tag1_activeName == 'tag1_categarySummary') {
                exportUrl = api.selfExportFl
                fileName = '客户自费维保 分类汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag2' && this.tag2_activeName == 'tag2_summary') {
                exportUrl = api.saleExportAll
                fileName = '销售赠送 总汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag2' && this.tag2_activeName == 'tag2_categarySummary') {
                exportUrl = api.saleExportFl
                fileName = '销售赠送 分类汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag3' && this.tag3_activeName == 'tag3_summary') {
                exportUrl = api.afterExportAll
                fileName = '售后赠送 总汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag3' && this.tag3_activeName == 'tag3_categarySummary') {
                exportUrl = api.afterExportFl
                fileName = '售后赠送 分类汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag4' && this.tag4_activeName == 'tag4_summary') {
                exportUrl = api.financeExportAll
                fileName = '金融保险赠送 总汇总表'
            } else if (this.mcbs_activeIndex == 'mcbs_tag4' && this.tag4_activeName == 'tag4_categarySummary') {
                exportUrl = api.financeExportFl
                fileName = '金融保险赠送 分类汇总表'
            }
            //#endregion
            exportUrl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        }
    },
}
</script>
<style scoped>
.platformContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sf_main {
    flex: 1;
    background-color: rgb(247, 248, 250);
    padding-top: .25rem;
    display: flex;
    flex-direction: column;
}

.sf_des {
    background-color: white;
    border: 1px solid rgb(235, 235, 235);
    padding: .25rem;
    border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
    padding-right: .25rem;
}

.sf_footer {
    flex: 1;
    margin-top: .125rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .125rem;
}

.sf_footer .tableStyle {
    width: 100% !important;
    flex: 1;
    overflow-x: auto;
}

.sf_footer .show_data {
    width: 100%;
    height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
    display: flex;
    justify-content: flex-end;
}

.el-tabs--border-card {
    width: 100%;
}
</style>