<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="ind_defaultIndex" class="el-menu-demo" mode="horizontal" @select="ind_handleSelect">
                <el-menu-item index="ind_tag1">保险赠送储值明细</el-menu-item>
                <el-menu-item index="ind_tag2">保险赠送消费明细</el-menu-item>
            </el-menu>
        </div>
        <div class="sf_main">
            <div class="sf_des">
                <el-descriptions class="margin-top" :column="5" size="mini" :labelStyle="label_style1" :colon="false">
                    <el-descriptions-item label="客户名称" :span="1">
                        <el-input v-model="form1.name" :size="size" placeholder="客户名称搜索"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号" :span="1">
                        <el-input v-model="form1.telephone" placeholder="手机号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="会员级别" :span="1">
                        <el-select v-model="form1.vipLevel" placeholder="请选择" clearable :size="size">
                            <el-option v-for="(item, index) in vipLevelList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="卡片性质" :span="1">
                        <el-select v-model="form1.cardType" placeholder="请选择" :size="size" clearable>
                            <el-option label="购买" value="0"></el-option>
                            <el-option label="赠送" value="1"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="充值方式" :span="1" v-if="ind_activeIndex == 'ind_tag1'">
                        <el-select class="dg" placeholder="请选择" v-model="form1.remehod" size="small" clearable>
                            <el-option v-for="(item, index) in Czlist" :key="index" :label="item.storedValueName"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="车牌号码" :span="1">
                        <el-input v-model="form1.carNum" placeholder="车牌号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="车架号码" :span="1">
                        <el-input v-model="form1.carFrameNumber" placeholder="车架号搜索" :size="size"></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="介绍人" :span="1">
                        <el-input v-model="form1.sellerId" :size="size" placeholder="介绍人搜索"></el-input>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="发卡店" :span="1">
                        <el-select v-model="form1.hairCard" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="操作部门" :span="1">
                        <el-select v-model="form1.operatingDept" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in operateDepartmentList" clearable :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item label="操作门店" :span="1">
                        <el-select v-model="form1.operationCard" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="(item, index) in StoreList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-descriptions-item> -->
                    <el-descriptions-item label="操作员工" :span="1">
                        <el-select v-model="form1.operatingName" placeholder="请选择" :size="size" clearable>
                            <el-option v-for="item in userList" :key="item.id" :label="item.login" :value="item.login">
                            </el-option>
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item label="操作时间" :span="2">
                        <el-date-picker size="small" clearable v-model="storageTime" type="daterange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <div class="operateBtn">
                            <el-button type="primary" :size="size" @click="check">查询</el-button>
                            <el-button type="primary" :size="size" @click="exportData" v-if="isExport == 1">导出数据</el-button>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="sf_footer" v-if="ind_activeIndex === 'ind_tag1'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" style="width:21rem">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="200">
                        </el-table-column>
                        <el-table-column prop="vipLevel" label="会员级别">
                        </el-table-column>
                        <el-table-column prop="vipName" label="会员姓名">
                        </el-table-column>
                        <el-table-column prop="carNum" label="车牌号码" width="200">
                        </el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号码" width="200">
                        </el-table-column>
                        <el-table-column prop="money" label="充值金额">
                        </el-table-column>
                        <el-table-column prop="credit" label="赠送积分">
                        </el-table-column>
                        <el-table-column prop="sellerId" label="介绍人">
                        </el-table-column>
                        <el-table-column prop="cardType" label="卡片性质">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cardType == '0'">购买</span>
                                <span v-if="scope.row.cardType == '1'">赠送</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="hairCard" label="发卡店">
                        </el-table-column>
                        <el-table-column prop="reMethod" label="充值方式">
                        </el-table-column>
                        <el-table-column prop="operationName" label="操作员">
                        </el-table-column>
                        <el-table-column prop="operationCard" label="操作店">
                        </el-table-column>
                        <el-table-column prop="operationDept" label="操作部门">
                        </el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="200">
                        </el-table-column>
                        <el-table-column prop="remarks" label="备注">
                        </el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getInsurancePage1"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>

                <div class="show_data">
                    <el-divider content-position="left" class="poi_title">汇总信息</el-divider>
                    <div class="summaryInfo_Bootom">
                        <div>记录数：{{ summaryData.countSum || 0 }}条
                            充值金额：{{ summaryData.money || 0 }}元
                            赠送积分：{{ summaryData.credit || 0 }}分</div>
                    </div>
                </div>
            </div>
            <div class="sf_footer" v-if="ind_activeIndex === 'ind_tag2'">
                <div class="tableStyle">
                    <el-table :data="dataList" :stripe="true" size="mini" header-cell-class-name="headerClassName"
                        :cell-style="{ 'text-align': 'center' }" :border="true" :fit="true" style="width:21rem">
                        <el-table-column prop="id" label="编号" width="70">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="waterNum" label="流水单号" width="200">
                        </el-table-column>
                        <el-table-column prop="constNum" label="施工单号" width="200">
                        </el-table-column>
                        <el-table-column prop="telephone" label="手机号" width="200">
                        </el-table-column>
                        <el-table-column prop="vipLevel" label="会员级别" width="120">
                        </el-table-column>
                        <el-table-column prop="vipName" label="会员姓名" width="120">
                        </el-table-column>
                        <el-table-column prop="carNum" label="车牌号码" width="200">
                        </el-table-column>
                        <el-table-column prop="carFrameNumber" label="车架号码" width="200">
                        </el-table-column>
                        <el-table-column prop="serviceType" label="维修类型" width="200">
                        </el-table-column>
                        <el-table-column prop="conMoney" label="消费金额" width="120">
                        </el-table-column>
                        <el-table-column prop="materMoney" label="优惠金额" width="120">
                        </el-table-column>
                        <el-table-column prop="thisBalance" label="本次余额" width="120">
                        </el-table-column>
                        <el-table-column prop="credit" label="累积积分" width="120">
                        </el-table-column>
                        <el-table-column prop="sellerId" label="介绍人">
                        </el-table-column>
                        <el-table-column prop="cardType" label="卡片性质" width="120">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cardType == '0'">购买</span>
                                <span v-if="scope.row.cardType == '1'">赠送</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="hairCard" label="发卡店">
                        </el-table-column>
                        <el-table-column prop="operationName" label="操作员">
                        </el-table-column>
                        <el-table-column prop="operationCard" label="操作店">
                        </el-table-column>
                        <el-table-column prop="operationDept" label="操作部门" width="120">
                        </el-table-column>
                        <el-table-column prop="operationTime" label="操作时间" width="200">
                        </el-table-column>
                        <el-table-column prop="remarks" label="备注">
                        </el-table-column>
                    </el-table>
                    <div class="sf_page">
                        <el-pagination background layout="prev, pager, next" :total="pageData.total"
                            :page-size="pageData.pageSize" @current-change="_getInsurancePage2"
                            :current-page.sync="pageData.page">
                        </el-pagination>
                    </div>
                </div>

                <div class="show_data">
                    <el-divider content-position="left" class="poi_title">汇总信息</el-divider>
                    <div class="summaryInfo_Bootom">
                        <div>记录数：{{ summaryData.countSum || 0 }}条
                            消费金额：{{ summaryData.consumptionTotalMoney || 0 }}元
                            优惠金额：{{ summaryData.materMoney || 0 }}元
                            累计积分：{{ summaryData.credit || 0 }}分</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div></template>
<script>
import * as api from '@/api/financialManage/selfFundedDetails'
import * as memberApi from '@/api/memberManage/index'
import { getStoredModel } from '@/api/memberDeposit';

export default {
    data() {
        return {
            ind_defaultIndex: 'ind_tag1',
            ind_activeIndex: 'ind_tag1',
            form1: {
                name: "", //会员姓名
                telephone: "", //手机号
                vipLevel: "", //会员等级id
                cardType: "", //卡片性质
                remehod: "", //充值方式
                carNum: "", //车牌号
                carFrameNumber: "", //车架号
                sellerId: "", //介绍人
                hairCard: "", //发卡人
                operatingDept: "", //操作部门
                operationCard: "", //操作门店
                operatingName: "", //操作人
                operatingStartTime: "", //操作时间（开始时间）
                operatingEndTime: "", //操作时间（结束时间
                operatingStartTime: '',
                operatingEndTime: ''
            },
            pageData: {
                total: 0,
                pageSize: 10, //分页大小
                page: 1,//分页页码，数字类型
            },
            dataList: [],
            storageTime: '',
            vipLevelList: [],
            StoreList: [],
            userList: [],
            summaryData: {},
            operateDepartmentList: [],
            Czlist: [],
            form: '',
            radio: '0',
            content_style: {
                'width': '15%',
            },
            size: 'small',
            label_style1: {
                'width': '1.3rem',
                'justify-content': 'end'
            },
            isExport: false
        }
    },
    // 使用
    mounted() {
        this.getCommn()
        this._getInsurancePage1()
        this.isExport = sessionStorage.getItem('isExport')
    },
    methods: {
        resetForm() {
            this.form1 = {
                name: "", //会员姓名
                telephone: "", //手机号
                vipLevel: "", //会员等级id
                cardType: "", //卡片性质
                remehod: "", //充值方式
                carNum: "", //车牌号
                carFrameNumber: "", //车架号
                sellerId: "", //介绍人
                hairCard: "", //发卡人
                operatingDept: "", //操作部门
                operationCard: "", //操作门店
                operatingName: "", //操作人
                operatingStartTime: "", //操作时间（开始时间）
                operatingEndTime: "", //操作时间（结束时间
                operatingStartTime: '',
                operatingEndTime: ''
            }
        },
        ind_handleSelect(key, keyPath) {
            this.ind_activeIndex = key
            this.pageData.page = 1
            this.resetForm()
            switch (key) {
                case 'ind_tag1':
                    this._getInsurancePage1()
                    break;
                case 'ind_tag2':
                    this._getInsurancePage2()
                    break;
                default:
                    break;
            }
        },
        getCommn() {
            memberApi.clientVipLevelGetList({}).then(res => {
                let { data } = res
                this.vipLevelList = data
            })
            memberApi.storeGetList({}).then(res => {
                let { data } = res
                this.StoreList = data
            })
            getStoredModel({}).then(res => {
                this.Czlist = res.data
            })
            api.getUserList().then(res => {
                if (res.state == '200') {
                    this.userList = res.data
                }
            })
            api.getStoreDepartmentList().then(res => {
                if (res.state == '200') {
                    this.operateDepartmentList = res.data
                }
            })
        },
        check() {
            this.pageData.page = 1
            if (this.ind_activeIndex == 'ind_tag1') {
                this._getInsurancePage1()
            } else if (this.ind_activeIndex == 'ind_tag2') {
                this._getInsurancePage2()
            }
        },
        _getInsurancePage1() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operatingStartTime = this.storageTime[0]
                this.form1.operatingEndTime = this.storageTime[1]
            } else {
                this.form1.operatingStartTime = ''
                this.form1.operatingEndTime = ''
            }
            api.getInsurancePage({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize, type: '1' }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
            api.getInsuranceRechargeSummary({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.summaryData = res.data
                }
            })
        },
        _getInsurancePage2() {
            if (this.storageTime && this.storageTime.length > 0) {
                this.form1.operatingStartTime = this.storageTime[0]
                this.form1.operatingEndTime = this.storageTime[1]
            } else {
                this.form1.operatingStartTime = ''
                this.form1.operatingEndTime = ''
            }
            api.getInsurancePage({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize, type: '0' }).then(res => {
                if (res.state == '200') {
                    this.dataList = res.data.records
                    this.pageData.total = res.data.total
                }
            })
            api.getInsuranceConsumptionSummary({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                if (res.state == '200') {
                    this.summaryData = res.data
                }
            })
        },
        exportData() {
            let exportUrl = {}
            let fileName = ''
            if (this.ind_activeIndex == 'ind_tag1') {
                exportUrl = api.exportInsuranceRechargePage
                fileName = '保险赠送充值明细'
            } else if (this.ind_activeIndex == 'ind_tag2') {
                exportUrl = api.exportInsuranceConsumptionPage
                fileName = '保险赠送消费明细'
            }
            exportUrl({ ...this.form1, page: this.pageData.page, pageSize: this.pageData.pageSize }).then(res => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = fileName //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        }
    },
}
</script>
<style scoped>
.platformContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sf_main {
    flex: 1;
    background-color: rgb(247, 248, 250);
    padding-top: .25rem;
    display: flex;
    flex-direction: column;
}

.sf_des {
    background-color: white;
    border: 1px solid rgb(235, 235, 235);
    padding: .25rem;
    border-radius: .125rem;
}


.sf_des .el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
    padding-right: .25rem;
}

.sf_footer {
    flex: 1;
    margin-top: .125rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .125rem;
}

.sf_footer .tableStyle {
    /* width: 100% !important; */
    width: 21rem !important;
    flex: 1;
    /* overflow-x: auto; */
}

.sf_footer .show_data {
    width: 100%;
    height: 1.125rem
}

.sf_footer .summaryInfo_Bootom {
    padding: 0 .5rem;
    font-size: large;
    font-weight: bold;
    color: rgb(92, 165, 243)
}

.sf_footer .sf_page {
    display: flex;
    justify-content: flex-end;
}
</style>